import React from 'react';

const RefundCancellationPolicy = () => {
  return (
    <div>
    <h1>Refund and Cancellation Policy</h1>
  
    <p><strong>Customer Policy</strong></p>
    <p><i>(Updated as on 24th July, 2023)</i></p>
  
    <p>
      <strong>IdeaDeck Innovations Private Limited</strong> (“Company”) is the sole owner of the brand BookMyChef and is committed to protecting the interests of its customers. The Company follows a transparent and reliable process to facilitate seamless service delivery.
    </p>
  
    <p>
      This Refund Policy (“Policy”), read with our  governs the services offered on the BookMyChef platform, available via our website and mobile application (“Platform”).
    </p>
  
    <p>
      For the purposes of this Policy, “Customer(s)” refers to users who book Service Partners through the Platform, and “Service Partner” refers to professionals offering their services on the Platform.
    </p>
  
    <h2>Refunds</h2>
  
    <h3>1. Cancellation by Customer</h3>
    <ul>
      <li>
        Customers can cancel bookings in advance. Refunds are issued as follows:
        <ul>
          <li>Full Refund: If a Service Partner has not been assigned to the booking.</li>
          <li>Full Refund: In cases of a no-show by the Service Partner.</li>
          <li>50% Refund: 
            <ul>
              <li>After the Service Partner is assigned but before ingredients are dispatched.</li>
              <li>Up to 12 hours before the Service Partner’s estimated arrival time.</li>
            </ul>
          </li>
          <li>No Refund:
            <ul>
              <li>If the Customer misses a booking or no one is present at the location.</li>
              <li>If the Service Partner arrives but ingredients or equipment are not ready.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Refunds are processed via the original payment method or as a credit note, based on the Customer’s preference.
      </li>
      <li>
        Refund processing may take up to 10 business days.
      </li>
    </ul>
  
    <h3>2. Cancellation by Company</h3>
    <ul>
      <li>
        The Company may cancel bookings due to unavoidable circumstances affecting Service Partner availability. In such cases, a full refund will be issued.
      </li>
      <li>
        If the cancellation is due to a breach of terms or misconduct by the Customer, no refund will be provided.
      </li>
    </ul>
  
    <h2>Chef/Service Partner Policy</h2>
  
    <p>
      This Policy, alongside the <a href="https://www.bookmychef.online/" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>, applies to Service Partners offering services via the Platform.
    </p>
  
    <h3>Refunds</h3>
    <p>
      Payments made to Service Partners for services rendered are non-refundable once processed.
    </p>
  
    <h3>Cancellation</h3>
  
    <h4>1. Cancellation by Service Partner</h4>
    <ul>
      <li>
        Service Partners may cancel 1 booking per month if the cancellation occurs at least 72 hours before the scheduled event. However, cancellations will negatively impact their rating.
      </li>
      <li>
        Cancellation allowances do not carry forward to subsequent months.
      </li>
    </ul>
  
    <h4>2. Cancellation by Company</h4>
    <ul>
      <li>
        The Company may cancel bookings in unavoidable circumstances or if the booking is deemed unsafe or unlawful.
      </li>
      <li>
        In cases of misconduct or breach of terms by the Service Partner, the Company reserves the right to withhold fees and recover incurred costs.
      </li>
    </ul>
  
    <p>
      <strong>Disclaimer:</strong> The Company reserves the right to amend this Policy at any time without prior notice. Continued use of the Platform constitutes acceptance of the amended Policy.
    </p>
  </div>
  
  );
};

export default RefundCancellationPolicy;
