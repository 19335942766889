// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

// Importing all components
import MainHomeComponent from './components/MainHomeComponent';
import LoginPage from './components/LoginPage';
import OrderBook from './components/OrderBook';
import ContactPage from './components/Contact';
import Preview from './components/Preview';
import AdminDashboard from './components/AdminDashboard';
import AdminLogin from './components/AdminLogin';
import RefundCancellationPolicy from './components/RefundCancellationPolicy';

function App() {
  return (
       <Routes>
        {/* Define all routes with their respective components */}
        <Route path="/home" element={<MainHomeComponent />} />
        <Route path="/LoginPage" element={<LoginPage />} />
        <Route path="/OrderBook" element={<OrderBook />} />
        <Route path="/ContactPage" element={<ContactPage />} />
        <Route path="/Preview" element={<Preview />} />
        <Route path="/SupperAdminDashboard" element={<AdminDashboard />} />
        <Route path="/SupperAdminLogin" element={<AdminLogin />} />
        <Route path="/" element={<RefundCancellationPolicy />} />
      </Routes>
 );
}

export default App;
